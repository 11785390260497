import React from 'react';
import {
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Hidden,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

import clsx from 'clsx';
import { CustomTooltip } from '../Shared/CustomTooltip';
import { DialogAllCreateIdea } from '../CreateIdeaDialogs';
import { ideaCreateDialogsActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    backgroundColor: theme.palette.auxiliary.beige.light,
  },
  card: {
    padding: 0,
    paddingBottom: '0 !important',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerButtonsIcons: {
    display: 'flex',
  },
  buttonCreateIdea: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  buttonSpanCreateIdea: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  buttonIcon: {
    color: theme.palette.grey.darkest,
  },
  buttonPhoto: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  buttonVideo: {
    '&:hover': {
      color: theme.palette.info.main,
    },
  },
  buttonDescription: {
    '&:hover': {
      color: theme.palette.auxiliary.green.light,
    },
  },
  buttonAttachment: {
    '&:hover': {
      color: theme.palette.warning.main,
    },
  },
  buttonCanvas: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  buttonValueCanvas: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export function IdeaCreateBoxFeed() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  function firstName() {
    return user.name.split(' ')[0];
  }

  function handleOpenDialog(openFunction) {
    dispatch(openFunction())
  }
  return (
    <div>
      <DialogAllCreateIdea />

      <Card className={classes.root} elevation={0}>
        <CardContent className={clsx(classes.container, classes.card)}>
          <div
            className={classes.buttonCreateIdea}
            onClick={() =>
              handleOpenDialog(ideaCreateDialogsActions.openDialogTitleDescription)
            }
          >
            <EditIcon
              style={{ marginRight: 6 }}
              className={classes.buttonIcon}
            />
            <span className={classes.buttonSpanCreateIdea}>
              {t('em_qual_ideia_voce_esta_pensando')}, {firstName()}?
            </span>
          </div>
          <Hidden xsDown>
            <div className={classes.containerButtonsIcons}>
              <div>
                <CustomTooltip
                  title={t('anexar_foto')}
                  aria-label={t('anexar_foto')}
                >
                  <IconButton
                    className={clsx(classes.buttonIcon, classes.buttonPhoto)}
                    onClick={() =>
                      handleOpenDialog(ideaCreateDialogsActions.openDialogPhoto)
                    }
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                </CustomTooltip>
              </div>
              <div>
                <CustomTooltip
                  title={t('anexar_video')}
                  aria-label={t('anexar_video')}
                >
                  <IconButton
                    className={clsx(classes.buttonIcon, classes.buttonVideo)}
                    onClick={() =>
                      handleOpenDialog(ideaCreateDialogsActions.openDialogVideo)
                    }
                  >
                    <VideocamIcon />
                  </IconButton>
                </CustomTooltip>
              </div>
              <div>
                <CustomTooltip title={t('anexar_link')} aria-label={t('anexar_link')}>
                  <IconButton
                    className={clsx(
                      classes.buttonIcon,
                      classes.buttonAttachment
                    )}
                    onClick={() =>
                      handleOpenDialog(ideaCreateDialogsActions.openDialogLink)
                    }
                  >
                    <LinkIcon />
                  </IconButton>
                </CustomTooltip>
              </div>
            </div>
          </Hidden>
        </CardContent>
      </Card>
    </div>
  );
}