import React, { useState } from 'react';
import {
  makeStyles,
  DialogContent,
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import config from '../../config';
import { dialogCss } from './dialogCss';
import { Input, MentionsUsers } from '../Shared';
import { DialogCreateIdea } from './DialogCreateIdea';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateDialogsActions, ideaCreateActions } from '../../actions';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyMaskCustomInput } from '../../helpers';
import { useCreateIdeaController } from './hooks/useCreateIdeaController';
import { CreateIdeaActions } from './CreateIdeaActions';
import { CardThumbnail } from './CardThumbnail';

const useStyles = makeStyles((theme) => ({
  message: {
    height: 19,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
  },
  characters: {
    position: 'absolute',
    right: 10,
  },
  fieldTitle: {
    fontWeight: 600,
    color: theme.palette.neutrals.low.medium,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 5
  },
  questionsContainer: {
    paddingBottom: theme.spacing(4)
  },
  cardThumbnail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function DialogTitleDescription() {
  const isIdeaStepFlux = config.SHOW_IDEAS_BY_STEP_FLUX;
  const isInnovationFields = config.SHOW_ADITIONAL_INNOVATION_FIELDS;
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const idea = useSelector((state) => state.ideaCreate);
  const { innovationQuestions, cardThumbnail } = useSelector((state) => state.ideaCreate);
  const { dialogTitleDescription } = useSelector((state) => state.ideaCreateDialogs);
  const { register, errors, handleSubmit } = useForm({ mode: 'onBlur' });
  const [value, setValue] = useState('');
  const { close } = useCreateIdeaController();

  function handleCharacters(e) {
    setValue(e.target.value)
  }

  function onSubmit() {
    dispatch(ideaCreateDialogsActions.openDialogMultiArea('DIALOG_TITLE_DESCRIPTION'));
  }

  function handleChangeFieldValue(e) {
    const { name, value } = e.target;
    dispatch(ideaCreateActions.setFieldsTitleDescription(name, value));
  }

  function handleMentions(mentioned) {
    dispatch(ideaCreateActions.setContributors(mentioned));
  }

  function handleSetFields(e, index) {
    const { value } = e.target;
    dispatch(ideaCreateActions.setFieldsAnswer({ index, answer: value }));
  }

  const SelectProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <DialogCreateIdea open={dialogTitleDescription}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('qual_e_a_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={dialogClasses.dividerBottom}>
        <Input
          name="name"
          variant={isInnovationFields ? 'filled' : 'standard'}
          defaultValue={idea.name}
          onChange={handleCharacters}
          onBlur={handleChangeFieldValue}
          error={!!errors.name}
          label={t('titulo').toUpperCase()}
          style={{ margin: 8 }}
          placeholder={t('de_um_titulo_para_sua_ideia')}
          InputProps={{ disableUnderline: !isInnovationFields && true }}            
          inputProps={{ maxLength: 70 }}
          inputRef={register({
            required: t('o_titulo_da_ideia_e_obrigatorio'),
            maxLength: 70,
          })}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.25rem', fontWeight: 600 },
          }}
          helperText={
            <div className={classes.message}>
              {(errors.name && errors.name.message)}
              {value && (
                <div className={classes.characters}>
                  <span>{value.length}/70 caracteres</span>
                </div>
              )}
            </div>
          }
        />
        <Input
          name="description"
          variant={isInnovationFields ? 'filled' : 'standard'}
          defaultValue={idea.description}
          onBlur={handleChangeFieldValue}
          error={!!errors.description}
          label={t('descricao_ideia').toUpperCase()}
          style={{ margin: 8 }}
          placeholder={t('em_qual_ideia_voce_esta_pensando_descreva')}
          InputProps={{ disableUnderline: !isInnovationFields && true }}
          multiline
          minRows={8}
          inputRef={register({
            required: t('a_descricao_da_ideia_e_obrigatoria'),
            style:({fontSize: '0.75rem',})
          })}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.25rem', fontWeight: 600 },
          }}
          helperText={
            <div className={classes.message}>
              {errors.description && errors.description.message}
            </div>
          }
        />

        {isIdeaStepFlux && (<>
          <Input
            name="problem"
            variant={isInnovationFields ? 'filled' : 'standard'}
            defaultValue={idea.problem}
            onBlur={handleChangeFieldValue}
            error={!!errors.problem}
            label={t('problema_identificado').toUpperCase()}
            style={{ margin: 8 }}
            placeholder={t('descreva_qual_foi_oportunidade/problema _que_foi_identificado')}
            InputProps={{ disableUnderline: !isInnovationFields && true }} 
            multiline
            minRows={8}
            inputRef={register({
              required: t('o_problema_identificado_da_ideia_e_obrigatoria'),
              style:({fontSize: '0.75rem',})
            })}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1.25rem', fontWeight: 600 },
            }}
            helperText={
              <div className={classes.message}>
                {errors.problem && errors.problem.message}
              </div>
            }
          />

          <Box>
           <Typography className={classes.fieldTitle}>{t('estrutura_de_custos').toUpperCase()}</Typography>
           <Grid container spacing={4}>
              <Grid item>
               <CurrencyMaskCustomInput
                  name="estimatedExecutionCost"
                  value={idea?.estimatedExecutionCost}
                  onChange={handleChangeFieldValue}
                  onBlur={handleChangeFieldValue}
                  error={!!errors.estimatedExecutionCost}
                  type="text"
                  variant="filled"
                  label={t('custos_previstos')}
                  fullWidth
                  inputRef={register({
                    required: t('o_custo_previsto_da_ideia_e_obrigatorio'),
                    style:({fontSize: '0.75rem',})
                  })}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '1.25rem', fontWeight: 600 },
                  }}
                  helperText={
                    <div className={classes.message}>
                      {errors.estimatedExecutionCost && errors.estimatedExecutionCost.message}
                    </div>
                  }
                />
              </Grid>
              <Grid item>
                <CurrencyMaskCustomInput
                  name="estimatedFinancialReturn"
                  value={idea?.estimatedFinancialReturn}
                  onChange={handleChangeFieldValue}
                  onBlur={handleChangeFieldValue}
                  error={!!errors.estimatedFinancialReturn}
                  type="text"
                  variant="filled"
                  label={t('retorno_previsto')}
                  fullWidth
                  inputRef={register({
                    required: t('o_retorno_previsto_da_ideia_e_obrigatorio'),
                    style:({fontSize: '0.75rem',})
                  })}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '1.25rem', fontWeight: 600 },
                  }}
                  helperText={
                    <div className={classes.message}>
                      {errors.estimatedFinancialReturn && errors.estimatedFinancialReturn.message}
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </>)}

        {isInnovationFields && (
          <Grid container spacing={3} className={classes.questionsContainer}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{('Inovação').toUpperCase()}</Typography>
            </Grid>

            {innovationQuestions.map((item, index) => (
              <Grid item xs={12} key={item._id}>
                {(!item?.isSelect && !item?.attachmentRequired) && (
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    variant="filled"
                    id={item._id}
                    name="answer"
                    label={item.question}
                    defaultValue={item?.answer}
                    onBlur={(e) => handleSetFields(e, index)}
                  />
                )}

                {(item?.isSelect && !item?.attachmentRequired) && (
                  <FormControl fullWidth variant="filled">
                    <InputLabel>{item.question}</InputLabel>
                      <Select
                        MenuProps={SelectProps}
                        defaultValue={item?.answer}
                        onChange={(e) => handleSetFields(e, index)}
                      >
                      {item?.valuesToSelect.map((value, index) => (
                        <MenuItem value={value} key={index}> {value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            ))}
          </Grid>
        )}

        <Box className={classes.cardThumbnail}>
          <CardThumbnail type="thumbnail" image={cardThumbnail} />
        </Box>

        <MentionsUsers onAddMentions={handleMentions} />
      </DialogContent>

      <CreateIdeaActions name="DIALOG_TITLE_DESCRIPTION" onSubmit={handleSubmit(onSubmit)} />
    </DialogCreateIdea>
  );
}

export { DialogTitleDescription };
