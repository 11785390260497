import { timelineConstanst } from '../constants';
import update from 'react-addons-update';

const initalState = {
  ideas: [],
  highlightIdeas: [],
  paging: {
    page: 1,
    sort: { timelineDate: -1 },
    limit: 10,
    skip: 0,
    search: '',
    hasNextPage: "",
  },
  pagingIdea: {
    page: 1,
    sort: { createdAt: -1 },
    limit: 10,
    query: { name: 'name', value: '' }
  },
  loading: false,
  loadingUpIdea: false,
  loadingUpComment: false,
  loadingChallenges: false,
  challenges: [],
};

export function timeline(state = initalState, action) {
  switch (action.type) {
    case timelineConstanst.TIMELINE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case timelineConstanst.TIMELINE_SUCCESS:
      if (action.payload.paging.page === 1) { 
        return {
          ...state,
          loading: false,
          ideas: action.payload.ideas,
          paging: {
            ...state.paging,
            page: action.payload.paging.page + 1,
            hasNextPage: action.payload.paging.hasNextPage
          }
        }
      } else {
        return {
          ...state,
          loading: false,
          ideas: [
            ...state.ideas,
            ...action.payload.ideas,
          ],
          paging: {
            ...state.paging,
            page: action.payload.paging.page + 1,
            hasNextPage: action.payload.paging.hasNextPage
          }
        }
      }

    case timelineConstanst.SET_TIMELINE_SEARCH: {
      return {
        ...state,
        paging: {
          ...state.paging,
          search: action.payload
        },
      }
    }

    case timelineConstanst.TIMELINE_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case timelineConstanst.SORT_TIMELINE_CHANGE:
      return update(state, {
        ideas: { $set: action.payload.ideas },
        paging: {
          page: { $set: action.payload.paging.page + 1 },
          sort: { $set: action.payload.sort },
        },
      });

    case timelineConstanst.SORT_TIMELINE_CHANGE_FAILURE:
      return update(state, {
        paging: {
          sort: { $set: action.payload.sort },
        },
      });

    case timelineConstanst.UP_IDEA_REQUEST:
      return {
        ...state,
        loadingUpIdea: true
      }

    case timelineConstanst.UP_IDEA_SUCCESS:
      return {
        ...state,
        loadingUpIdea: false
      }

    case timelineConstanst.UP_IDEA_FAILURE:
      return {
        ...state,
        loadingUpIdea: false
      }

    case timelineConstanst.UP_IDEA_CASE_REQUEST:
      return {
        ...state,
        loadingUpIdea: true
      }

    case timelineConstanst.UP_IDEA_CASE_SUCCESS:
      return update(state, {
        highlightIdeas: {
          [action.payload.id]: {
            hasUserUpped: { $set: action.payload.idea.hasUserUpped },
            up: { $set: action.payload.idea.hasUserUpped },
            ups: { $set: action.payload.idea.ups },
            upsLength: { $set: action.payload.idea.ups.length },
          },
        },
        loadingUpIdea: { $set: false },
      });

    case timelineConstanst.UP_IDEA_CASE_FAILURE:
      return {
        ...state,
        loadingUpIdea: false
      }

    case timelineConstanst.FAVORITE_IDEA_SUCCESS:
      return update(state, {
        ideas: {
          [action.payload.index]: {
            favorite: { $set: !state.ideas[action.payload.index].favorite },
          },
        },
      });

    case timelineConstanst.FAVORITE_IDEA_FAILURE:
      return state;

    case timelineConstanst.COMMENTS_IDEA_SUCCESS:
      if (!state.ideas[action.payload.indexIdea].comments) {
        state.ideas[action.payload.indexIdea].comments = [];
      }

      return update(state, {
        ideas: {
          [action.payload.indexIdea]: {
            comments: { $push: action.payload.comments },
            pagingComment: { $set: action.payload.paging },
          },
        },
      });

    case timelineConstanst.COMMENTS_IDEA_FAILURE:
      return state;

    case timelineConstanst.UP_COMMENTS_IDEA_REQUEST:
      return update(state, {
        loadingUpComment: { $set: true },
      });

    case timelineConstanst.UP_COMMENTS_IDEA_SUCCESS:
      return update(state, {
        ideas: {
          [action.payload.postIndex]: {
            comments: {
              [action.payload.commentIndex]: {
                ups: { $set: action.payload.comment.ups },
                upsLength: { $set: action.payload.comment.upsLength },
                hasUserUpped: { $set: action.payload.comment.up },
              },
            },
          },
        },
        loadingUpComment: { $set: false },
      });

    case timelineConstanst.UP_COMMENTS_IDEA_FAILURE:
      return update(state, {
        loadingUpComment: { $set: false },
      });

    case timelineConstanst.REMOVE_COMMENTS_IDEA_SUCCESS:
      return update(state, {
        ideas: {
          [action.payload.postIndex]: {
            commentsLenght: {
              $set: state.ideas[action.payload.postIndex].commentsLenght - 1,
            },
            pagingComment: {
              total: {
                $set:
                  state.ideas[action.payload.postIndex].pagingComment.total - 1,
              },
            },
            comments: {
              $set: state.ideas[action.payload.postIndex].comments.filter(
                (comment) => comment._id !== action.payload.id
              ),
            },
          },
        },
      });

    case timelineConstanst.REMOVE_COMMENTS_IDEA_FAILURE:
      return state;

    case timelineConstanst.CREATE_COMMENT_IDEA_SUCCESS:
      return update(state, {
        ideas: {
          [action.payload.postIndex]: {
            comments: { $unshift: [action.payload.comment] },
            commentsLenght: {
              $set: state.ideas[action.payload.postIndex].commentsLenght + 1,
            },
          },
        },
      });

    case timelineConstanst.CREATE_COMMENT_IDEA_FAILURE:
      return state;

    case timelineConstanst.GET_BANNERS_CHALLENGE_SUCCESS: {
      return update(state, {
        loadingChallenges: { $set: false },
        challenges: { $set: action.payload.challenges },
      });
    }

    case timelineConstanst.GET_BANNERS_CHALLENGE_FAILURE: {
      return update(state, {
        loadingChallenges: { $set: false },
      });
    }

    case timelineConstanst.GET_BANNERS_CHALLENGE_REQUEST: {
      return update(state, {
        loadingChallenges: { $set: true },
      });
    }

    case timelineConstanst.CLEAR_TIMELINE_ALL: {
      return {
        ...state,
        ideas: [],
        paging: {
          ...state.paging,
          search: "",
        }
      }
    }

    case timelineConstanst.GET_HIGHLIGHT_IDEAS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case timelineConstanst.GET_HIGHLIGHT_IDEAS_SUCCESS:
      return {
        ...state,
        highlightIdeas: action.payload.data,
        loading: false,
        pagingIdea: {
          ...state.paging,
          page: action.payload.paging.page,
          sort: action.payload.paging.sort,
          limit: action.payload.paging.limit,
          query: action.payload.query
        },
      };

    case timelineConstanst.GET_HIGHLIGHT_IDEAS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
