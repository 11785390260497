export const ideaConstants = {
  SET_IDEA_DETAILS_PAGE: "SET_IDEA_DETAILS_PAGE",
  GET_USER_IDEAS_IMAGINE_REQUEST: 'GET_USER_IDEAS_IMAGINE_REQUEST',
  GET_USER_IDEAS_IMAGINE_SUCCESS: 'GET_USER_IDEAS_IMAGINE_SUCCESS',
  GET_USER_IDEAS_IMAGINE_FAILURE: 'GET_USER_IDEAS_IMAGINE_FAILURE',

  GET_USER_IDEAS_IMAGINE_REQUEST_MOBILE:
    'GET_USER_IDEAS_IMAGINE_REQUEST_MOBILE',
  GET_USER_IDEAS_IMAGINE_SUCCESS_MOBILE:
    'GET_USER_IDEAS_IMAGINE_SUCCESS_MOBILE',
  GET_USER_IDEAS_IMAGINE_FAILURE_MOBILE:
    'GET_USER_IDEAS_IMAGINE_FAILURE_MOBILE',

  POST_IDEA_DRAFT_IMAGINE_REQUEST: 'POST_IDEA_DRAFT_IMAGINE_REQUEST',
  POST_IDEA_DRAFT_IMAGINE_SUCCESS: 'POST_IDEA_DRAFT_IMAGINE_SUCCESS',
  POST_IDEA_DRAFT_IMAGINE_FAILURE: 'POST_IDEA_DRAFT_IMAGINE_FAILURE',

  GET_IDEA_USER_DETAILS_REQUEST: 'GET_IDEA_USER_DETAILS_REQUEST',
  GET_IDEA_USER_DETAILS_SUCCESS: 'GET_IDEA_USER_DETAILS_SUCCESS',
  GET_IDEA_USER_DETAILS_FAILURE: 'GET_IDEA_USER_DETAILS_FAILURE',

  GET_PENDING_IDEAS_REQUEST: 'GET_PENDING_IDEAS_REQUEST',
  GET_PENDING_IDEAS_SUCCESS: 'GET_PENDING_IDEAS_SUCCESS',
  GET_PENDING_IDEAS_FAILURE: 'GET_PENDING_IDEAS_FAILURE',

  DELETE_DRAFT_REQUEST: 'DELETE_DRAFT_REQUEST',
  DELETE_DRAFT_SUCCESS: 'DELETE_DRAFT_SUCCESS',
  DELETE_DRAFT_FAILURE: 'DELETE_DRAFT_FAILURE',

  TOGGLE_FILTER: 'TOGGLE_FILTER',

  PUT_IDEA_APPROVED_REQUEST: 'PUT_IDEA_APPROVED_REQUEST',
  PUT_IDEA_APPROVED_SUCCESS: 'PUT_IDEA_APPROVED_SUCCESS',
  PUT_IDEA_APPROVED_FAILURE: 'PUT_IDEA_APPROVED_FAILURE',

  SET_ID_APPROVED: 'SET_ID_APPROVED',
  CLEAR_APPROVED: 'CLEAR_APPROVED',
  SET_EVALUATION_APPROVED: 'SET_EVALUATION_APPROVED',
  SET_APPROVED: 'SET_APPROVED',
  SET_BUL: 'SET_BUL',

  PUT_IDEA_DELEGATE_REQUEST: 'PUT_IDEA_DELEGATE_REQUEST',
  PUT_IDEA_DELEGATE_SUCCESS: 'PUT_IDEA_DELEGATE_SUCCESS',
  PUT_IDEA_DELEGATE_FAILURE: 'PUT_IDEA_DELEGATE_FAILURE',

  SET_ID_DELEGATE: 'SET_ID_DELEGATE',
  CLEAR_DELEGATE: 'CLEAR_DELEGATE',

  CLEAR_IDEA_DETAILS: 'CLEAR_IDEA_DETAILS',

  GET_STATUS_IDEAS_REQUEST: 'GET_STATUS_IDEAS_REQUEST',
  GET_STATUS_IDEAS_SUCCESS: 'GET_STATUS_IDEAS_SUCCESS',
  GET_STATUS_IDEAS_FAILURE: 'GET_STATUS_IDEAS_FAILURE',

  TOGGLE_IDEA_IN_TIMELINE_REQUEST: "TOGGLE_IDEA_IN_TIMELINE_REQUEST",
  TOGGLE_IDEA_IN_TIMELINE_SUCCESS: 'TOGGLE_IDEA_IN_TIMELINE_SUCCESS',
  TOGGLE_IDEA_IN_TIMELINE_FAILURE: 'TOGGLE_IDEA_IN_TIMELINE_FAILURE',

  SET_PERSONALIZED_CLASSIFICATION_APPROVED: "SET_PERSONALIZED_CLASSIFICATION_APPROVED",
  SET_PERSONALIZED_CLASSIFICATION_IDEA_DETAILS: "SET_PERSONALIZED_CLASSIFICATION_IDEA_DETAILS",
  SET_IDEA_QUALIFICATION: "SET_IDEA_QUALIFICATION",
  SET_QUALIFICATION_FIELDS_ERRORS: "SET_QUALIFICATION_FIELDS_ERRORS",

  CLEAR_APPROVED_DETAILS: "CLEAR_APPROVED_DETAILS",

  DIALOG_CREATE_ARTICLES_QUESTIONS_REQUEST: 'DIALOG_CREATE_ARTICLES_QUESTIONS_REQUEST',
  DIALOG_CREATE_ARTICLES_QUESTIONS_SUCCESS: 'DIALOG_CREATE_ARTICLES_QUESTIONS_SUCCESS',
  DIALOG_CREATE_ARTICLES_QUESTIONS_FAILURE: 'DIALOG_CREATE_ARTICLES_QUESTIONS_FAILURE',
  DIALOG_CREATE_LOAD_ARTICLE_QUESTIONS: 'DIALOG_CREATE_LOAD_ARTICLE_QUESTIONS',
  DIALOG_CREATE_SET_ARTICLES_QUESTIONS: 'DIALOG_CREATE_SET_ARTICLES_QUESTIONS',
  DIALOG_CREATE_SET_CARD_THUMBNAIL: 'DIALOG_CREATE_SET_CARD_THUMBNAIL',
  DIALOG_CREATE_REMOVE_CARD_THUMBNAIL: 'DIALOG_CREATE_REMOVE_CARD_THUMBNAIL',

  UPDATE_UP_IDEA_REQUEST: 'UPDATE_UP_IDEA_REQUEST',
  UPDATE_UP_IDEA_SUCCESS: 'UPDATE_UP_IDEA_SUCCESS',
  UPDATE_UP_IDEA_FAILURE: 'UPDATE_UP_IDEA_FAILURE',
};
