import { configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import rootReducer from '../reducers';
import localforage from 'localforage';
import config from '../config';

const persistConfig = {
  key: `root-${config.BUILD_VERSION}`,
  version: 1,
  storage: localforage,
  //whitelist: ['timeline'] // only timeline will be persisted

  blacklist: [
    'evaluationTeams',
    'generalEvaluation',
    'fluxIdeas',
    'ideaUpdate',
    'idea',
    'ideaCreate',
    'userFunction',
    'execution',
    'articlesReducer',
    'report',
    'infraStructure'
  ]

}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const middlewares = [];
console.log("Build: "+ config.BUILD_VERSION)
//redux thunk ja imbutido no reduxjs toolkit atualizado
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development'
});
