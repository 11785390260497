import config from '../config';
import axios from 'axios';
import { handleResponse, requestHeaders } from '../helpers';

export const timelineService = {
  getTimeline,
  upIdea,
  getCommentsByIdea,
  upComment,
  favoriteIdea,
  removeCommentIdea,
  newComment,
  getBannersChallenge,
  getHighlightIdeas
};

function getTimeline(page, sort, search, quantityPerPage = 10) {
  const url = `${
    config.URL_BASE
  }/timeline?q=${search}&page=${page}&sort=${JSON.stringify(sort)}`;

  return axios.get(url, requestHeaders()).then(handleResponse);
}

function upIdea(id) {
  return axios
    .put(`${config.URL_BASE}/timeline/ups/${id}`, {}, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}

function getCommentsByIdea(ideaId, page, sort, quantityPerPage = 6) {
  const url = `${
    config.URL_BASE
  }/comments/${ideaId}?page=${page}&sort=${JSON.stringify(
    sort
  )}&quantityPerPage=${quantityPerPage}`;

  return axios.get(url, requestHeaders()).then(handleResponse);
}

function upComment(id) {
  return axios
    .put(`${config.URL_BASE}/comments/${id}/ups`, {}, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}

function newComment(comment) {
  return axios
    .post(`${config.URL_BASE}/comments`, comment, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}

function favoriteIdea(id) {
  return axios
    .put(`${config.URL_BASE}/timeline/favorite/${id}`, {}, requestHeaders())
    .then(handleResponse);
}

function removeCommentIdea(id) {
  return axios.delete(`${config.URL_BASE}/comments/${id}`, requestHeaders());
}

function getBannersChallenge() {
  return axios
    .get(
      `${config.URL_BASE}/challenges?page=1&sort=${JSON.stringify({
        createdAt: -1,
      })}&limit=50`,
      requestHeaders()
    )
    .then(handleResponse);
}

function getHighlightIdeas(page, sort, limit, query) {
  const hasQuery = query
    ? `ideas/all?filter={"field":"name","value":"${query}"}&page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`
    : `ideas/all?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`;

  return axios
    .get(`${config.URL_BASE}/${hasQuery}`, requestHeaders())
    .then(handleResponse);
}