import { articlesService } from '../services';
import { articleConstants } from '../constants';
import { history } from '../helpers';
import { alertActions } from './alert.actions';
import { userActions } from './user.actions';

export const articleActions = {
  getArticles,
  getHighlightArticles,
  getMoreArticles,
  getArticleQuestions,
  clear,
  createArticle,
  putArticle,
  setArticle,
  setPropArticle,
  setArticleImage,
  removeArticleImage,
  setImageToRemoveArticle,
  imageNextCardArticle,
  getArticleById,
  deleteArticle,
  setAuthors,
  setStatus,
  setHighlight,
  setFieldsAnswer,
  loadArticleQuestions,
  loadArticleDetails,
  upArticle
};

function setHighlight(highlight, isEdit = false) {
  return dispatch => {
    if (isEdit) {
      dispatch({ type: articleConstants.SET_ARTICLE_HIGHLIGHT_EDIT, payload: highlight })
    } else {
      dispatch({ type: articleConstants.SET_ARTICLE_HIGHLIGHT, payload: highlight })
    }
  }
}

function setStatus(status, isEdit = false) {
  return dispatch => {
    if (isEdit) {
      dispatch({ type: articleConstants.SET_ARTICLE_STATUS_EDIT, payload: status })
    } else {
      dispatch({ type: articleConstants.SET_ARTICLE_STATUS, payload: status })
    }
  }
}

function setAuthors(authors, isEdit = false) {
  return dispatch => {
    if (isEdit) {
      dispatch({ type: articleConstants.SET_ARTICLE_AUTHORS_EDIT, payload: authors })
    } else {
      dispatch({ type: articleConstants.SET_ARTICLE_AUTHORS, payload: authors })
    }
  }
}

function deleteArticle(id, message) {
  return (dispatch) => {
    articlesService.deleteArticle(id).then(() => {
      dispatch({ type: articleConstants.DELETE_SUCCESS })
      dispatch(alertActions.success(message))
      history.push('/articles')

    }).catch(err => {
      console.error(err);
      dispatch({ type: articleConstants.DELETE_FAILURE })
    })
  }
}

function getArticleById(id) {
  return (dispatch) => {
    articlesService.getArticleById(id).then(({ data }) => {
      dispatch({ type: articleConstants.GET_ARTICLE_BY_ID_SUCCESS, payload: data })
    }).catch(err => {
      console.error(err);
      dispatch({ type: articleConstants.GET_ARTICLE_BY_ID_FAILURE })
    })
  }
}

function setArticleImage(type, image, isEditImage) {
  return { type: articleConstants.SET_ARTICLE_IMAGE, payload: { type, image, isEditImage } };
}

function removeArticleImage(type, edit) {
  return { type: articleConstants.REMOVE_ARTICLE_IMAGE, payload: { type, edit } };
}

function setImageToRemoveArticle(type) {
  return { type: articleConstants.SET_IMAGE_TO_REMOVE_ARTICLE, payload: { type } };
}

function imageNextCardArticle() {
  return { type: articleConstants.IMAGE_NEXT_CARD_ARTICLE };
}

function setArticle(article) {
  return { type: articleConstants.SET_ARTICLE, article };
}

function setPropArticle(value, name, isEdit) {
  return { type: articleConstants.SET_PROP_ARTICLE, value, name, isEdit };
}

function createArticle(newArticle, successMessage) {
  return (dispatch) => {
    dispatch(request());
    articlesService
      .createArticle(newArticle)
      .then((article) => {
        if (newArticle.thumbnail.file) {
          articlesService
            .uploadArticleImage('thumbnailFile', newArticle.thumbnail, article.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }

        if (newArticle.banner.file) {
          articlesService
            .uploadArticleImage('bannerFile', newArticle.banner, article.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }
        if (newArticle.attachmentQuestionOne.file) {
          articlesService
            .uploadArticleImage('attachmentQuestionOne', newArticle.attachmentQuestionOne, article.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }
        if (newArticle.attachmentQuestionTwo.file) {
          articlesService
            .uploadArticleImage('attachmentQuestionTwo', newArticle.attachmentQuestionTwo, article.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }
        dispatch(success());
        dispatch(alertActions.success(successMessage));
        history.push('/articles');
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error('Erro ao criar o artigo'));
      });
  };

  function request() {
    return { type: articleConstants.CREATE_ARTICLE_REQUEST };
  }

  function success() {
    return { type: articleConstants.CREATE_ARTICLE_SUCCESS };
  }

  function failure(error) {
    console.error(error);
    return { type: articleConstants.CREATE_ARTICLE_FAILURE, error };
  }
}

function putArticle(articleToPut, successMessage) {
  return (dispatch) => {
    dispatch(request());
    articlesService
      .putArticle(articleToPut)
      .then((article) => {
        if (articleToPut.thumbnail.file) {
          articlesService
            .uploadArticleImage('thumbnailFile', articleToPut.thumbnail, article.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }

        if (articleToPut.banner.file) {
          articlesService
            .uploadArticleImage('bannerFile', articleToPut.banner, article.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }
        if (newArticle.attachmentQuestionOne.file) {
          articlesService
            .uploadArticleImage('attachmentQuestionOne', newArticle.attachmentQuestionOne, article.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }
        if (newArticle.attachmentQuestionTwo.file) {
          articlesService
            .uploadArticleImage('attachmentQuestionTwo', newArticle.attachmentQuestionTwo, article.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }
        dispatch(success());
        dispatch(alertActions.success(successMessage));
        history.goBack();
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(alertActions.error('Erro ao editar o artigo'));
      });
  };

  function request() {
    return { type: articleConstants.PUT_ARTICLE_REQUEST };
  }

  function success() {
    return { type: articleConstants.PUT_ARTICLE_SUCCESS };
  }

  function failure(error) {
    console.error(error);
    return { type: articleConstants.PUT_ARTICLE_FAILURE, error };
  }
}



function getArticles(page, sort, limit, searchText) {
  return (dispatch) => {
    dispatch(request());
    articlesService
      .getArticles(page, sort, limit, searchText)
      .then(({ paging, data }) => {
        dispatch(success({ paging, data }));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: articleConstants.GET_ARTICLES_REQUEST };
  }

  function success({ paging, data }) {
    const payload = { paging, data };

    return { type: articleConstants.GET_ARTICLES_SUCCESS, payload };
  }

  function failure(error) {
    console.error(error);
    return { type: articleConstants.GET_ARTICLES_FAILURE, error };
  }
}

function getMoreArticles(page, sort, limit) {
  return (dispatch) => {
    articlesService
      .getArticles(page, sort, limit)
      .then(({data, paging}) => {
        dispatch(success(data, paging));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function success(data, paging) {
    const payload = {
     data, paging
    };

    return { type: articleConstants.GET_MORE_ARTICLES_SUCCESS, payload };
  }

  function failure(error) {
    console.error(error);
    return { type: articleConstants.GET_MORE_ARTICLES_FAILURE };
  }
}

function clear() {
  return (dispatch) => {
    dispatch({ type: articleConstants.CLEAR });
  };
}

function getArticleQuestions() {
  return (dispatch) => {
    dispatch({ type: articleConstants.GET_ARTICLES_QUESTIONS_REQUEST });

    articlesService.getArticleQuestions().then(({ data }) => {
      dispatch({
        type: articleConstants.GET_ARTICLES_QUESTIONS_SUCCESS,
        payload: data
      })
    }).catch((err) => {
      console.error(err);
      dispatch({ type: articleConstants.GET_ARTICLES_QUESTIONS_FAILURE })
    })
  }
}

function setFieldsAnswer(value) {
  return (dispatch) => {
    dispatch({
      type: articleConstants.SET_ARTICLES_QUESTIONS_FIELD_ANSWER,
      payload: value 
    });
  }
}

function loadArticleQuestions(data) {
  return (dispatch) => {
    dispatch({
      type: articleConstants.ARTICLE_LOAD_QUESTIONS,
      payload: data
    });
  }
}

function loadArticleDetails(data) {
  return (dispatch) => {
    dispatch({
      type: articleConstants.ARTICLE_LOAD_ARTICLES_DETAILS,
      payload: data
    });
  }
}

function upArticle(id, index) {
  return (dispatch) => {
    dispatch(request());
    articlesService.upArticle(id)
      .then((article) => {
        dispatch(success(article, index));
        dispatch(userActions.getUserDetailsRaking());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  
  function request() {
    return { type: articleConstants.UP_ARTICLE_REQUEST };
  }
  function success(article, index) {
    const payload = { article, index };
    return { type: articleConstants.UP_ARTICLE_SUCCESS, payload };
  }
  function failure(error) {
    return { type: articleConstants.UP_ARTICLE_FAILURE, error };
  }
}

function getHighlightArticles(page, sort, limit, query) {
  return (dispatch) => {
    dispatch({ type: articleConstants.GET_HIGHLIGHT_ARTICLES_REQUEST });
    articlesService
      .getHighlightArticles(page, sort, limit, query)
      .then(({ paging, data }) => {
        dispatch({
          type: articleConstants.GET_HIGHLIGHT_ARTICLES_SUCCESS,
          payload: { paging, data }
        });
    })
      .catch((error) => {
        dispatch({ type: articleConstants.GET_HIGHLIGHT_ARTICLES_FAILURE, error });
      });
  };
}
