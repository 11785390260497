import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemIcon, ListItemText, Typography, Divider } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import LampIcon from '@material-ui/icons/EmojiObjects';
import AddCommentIcon from '@material-ui/icons/AddComment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import HistoryIcon from '@material-ui/icons/History';
import { ReactComponent as FactCheckIcon } from '../../assets/imgs/fact-check-icon.svg';

import config from '../../config';
import { Copyright } from '../Shared';
import { useStyles, useStylesIcon } from './styles';
import { useIdeaDetailsController } from '../../containers/IdeaDetailsPage/hooks/useIdeaDetailsController';

export function SidebarIdeaDetails() {
  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const { t } = useTranslation();
  const { typeProfile: { type }, _id: userId } = useSelector((state) => state.user);
  const { ideaDetailsFlux, page } = useIdeaDetailsController();
  const ideaStepFlux = ideaDetailsFlux?.currentStepFlux?.ideaStepFlux;

  function routeExecutionOrConclusion() {
    if (ideaStepFlux?.ideaExecutionIsRequired && ideaDetailsFlux?.statusIdea?.code !="EXECUTING") {
      return `/idea/${ideaDetailsFlux._id}/execution`;
    } else if (ideaStepFlux?.ideaFinishExecutionIsRequired && ideaDetailsFlux?.statusIdea?.code =="EXECUTING") {
      return `/idea/${ideaDetailsFlux._id}/conclusion`;
    }
  }
  
  return (
    <>
      <List style={{ padding: 0 }}>
        <ListItem
          button
          replace
          component={Link}
          to={`/idea/${ideaDetailsFlux._id}/general`}
          className={
            page === "general"
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <LampIcon
              color={page === "general" ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={page === "general" ? 'primary' : 'inherit'}
              >
                {t('ideia_geral')}
              </Typography>
            }
          />
        </ListItem>

        {/* <ListItem
          button
          replace
          component={Link}
          to={`/idea/${ideaDetailsFlux._id}/engagement`}
          className={
            page === "engagement"
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <AddCommentIcon
              color={page === "engagement" ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={page === "engagement" ? 'primary' : 'inherit'}
              >
                {t('engajamento')}
              </Typography>
            }
          />
        </ListItem> */}

        {ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.visualizationConfigs?.showInEvaluationTab && ['admin', 'manager'].includes(type) && (
          <ListItem
            button
            replace
            component={Link}
            to={`/idea/${ideaDetailsFlux._id}/qualification`}
            className={
              page === "qualification"
                ? classes.backgroundActived
                : ''
            }
          >
            <ListItemIcon classes={classesIcon}>
              <ThumbUpIcon
                color={page === "qualification" ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  color={page === "qualification" ? 'primary' : 'inherit'}
                >
                  {t('avaliacao')}
                </Typography>
              }
            />
          </ListItem>
        )}

        {config.SHOW_PERSONALIZED_EVALUATION_ON_IDEA && ['admin', 'manager'].includes(type) &&  (
          <ListItem
            button
            component={Link}
            to={`/idea/${ideaDetailsFlux._id}/general-evaluation`}
            replace
            className={
              page === "general-evaluation"
                ? classes.backgroundActived
                : ''
            }
          >
            <ListItemIcon classes={classesIcon}>
              <FactCheckIcon style={{
                fill: page === "general-evaluation" ? "primary" : '#737373',
              }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  color={page === "general-evaluation" ? 'primary' : 'inherit'}
                >
                  {t('avaliacao_geral')}
                </Typography>
              }
            />
          </ListItem>
        )}

        {(ideaDetailsFlux?.contributors?.filter(item => item?._id === userId)?.length > 0 || ideaDetailsFlux?.user?._id === userId || type !== 'colaborator') &&
          ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.visualizationConfigs?.showInExecutionTab && (
          <ListItem
            button
            replace
            component={Link}
            to={routeExecutionOrConclusion}
            className={
              page === "execution" || page === "conclusion"
                ? classes.backgroundActived
                : ''
            }
            disabled={ideaStepFlux?.ideaExecutionIsRequired === false}
          >
            <ListItemIcon classes={classesIcon}>
              <BuildIcon
                color={page === "execution" || page === "conclusion" ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  color={page === "execution" || page === "conclusion" ? 'primary' : 'inherit'}
                >
                  {t('execucao').toLowerCase()}
                </Typography>
              }
            />
          </ListItem>
        )}

        {ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.visualizationConfigs?.showInValidationTab && ['admin', 'manager'].includes(type) && (
          <ListItem
            button
            replace
            component={Link}
            to={`/idea/${ideaDetailsFlux._id}/qualification`}
            className={
              page === "qualification"
                ? classes.backgroundActived
                : ''
            }
          >
            <ListItemIcon classes={classesIcon}>
              <ThumbUpIcon
                color={page === "qualification" ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  color={page === "qualification" ? 'primary' : 'inherit'}
                >
                  {t('validacao')}
                </Typography>
              }
            />
          </ListItem>
        )}

        <ListItem
          button
          replace
          component={Link}
          to={`/idea/${ideaDetailsFlux._id}/results`}
          className={
            page === "results"
              ? classes.backgroundActived
              : ''
          }
          disabled={ideaStepFlux?.showResultsInStep === false}
        >
          <ListItemIcon classes={classesIcon}>
            <AssignmentTurnedInIcon
              color={page === "results" ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={page === "results" ? 'primary' : 'inherit'}
              >
                {t('resultados')}
              </Typography>
            }
          />
        </ListItem>

        <ListItem
          button
          replace
          component={Link}
          to={`/idea/${ideaDetailsFlux._id}/history`}
          className={
            page === "history"
              ? classes.backgroundActived
              : ''
          }
          // disabled={ideaStepFlux?.showResultsInStep === false}
        >
          <ListItemIcon classes={classesIcon}>
            <HistoryIcon
              color={page === "history" ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={page === "history" ? 'primary' : 'inherit'}
              >
                {t('historico')}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider style={{ marginTop: 16 }} />          
      <Copyright />
    </>
  );
}
