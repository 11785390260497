import { ideaUpdateConstants } from '../constants';
import { ideaService, fluxOfIdeasService } from '../services';
import { history, getFiles } from '../helpers';
import { alertActions } from './alert.actions';
import config from '../config';
import { fluxOfIdeasActions, ideaActions } from '../actions';

export const ideaUpdateActions = {
  getIdeaDetails,
  updateIdea,
  clearIdeaDetails,
  updateTitleDescription,
  updateCanvas,
  updateCanvasValue,
  updateIdeaLinks,
  updateSetImages,
  removeImage,
  setSelectedBusiness,
  updateSetVideo,
  removeVideo,
  sendRemoveFiles,
  updateSetFile,
  removeFile,
  loadChallenges,
  loadClassifications,
  changeField,
  changeFieldClassification,
  addLink,
  removeLink,
  changeLink,
  clearFieldChallenge,
  clearFieldClassification,
  changeFieldParticipant,
  clearFieldParticipant,
  addNewParticipant,
  removeParticipant,
  loadParticipants,
  getParticipants
};

function getIdeaDetails(id) {
  return (dispatch) => {
    dispatch({ type: ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_REQUEST });
    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      fluxOfIdeasService.getIdeaInFlux(id).then((idea) => {
        dispatch({ 
          type: ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS,
          payload: idea
        });
      })
      .catch((error) => {
        dispatch({ 
          type: ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE,
          error
        });
      });
    } else {
      ideaService.getIdeaDetails(id).then((idea) => {
        dispatch({ 
          type: ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS,
          payload: idea
        });
      })
      .catch((error) => {
        dispatch({ 
          type: ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE,
          error
        });
      });
    }
    
  }
}

function clearIdeaDetails() {
  return { type: ideaUpdateConstants.CLEAR_UPTADE_IDEA_DETAILS };
}

function updateIdea(id, idea, successMessage) {
  return (dispatch) => {
    dispatch({ type: ideaUpdateConstants.UPDATE_IDEA_REQUEST });
      ideaService.updateIdea(id, idea).then((response) => {
        const files = getFiles(idea);
        ideaService.uploadFiles(files, idea._id)
          .then(() => {
            dispatch({ type: 'REGISTER_FILES' })
          });
        dispatch({ 
          type: ideaUpdateConstants.UPDATE_IDEA_SUCCESS,
          payload: response
        });
        history.goBack();
        dispatch(alertActions.success(successMessage));
        if (config.SHOW_IDEAS_BY_STEP_FLUX) {
          dispatch(fluxOfIdeasActions.getIdeaInFlux(id));
        } else {
          dispatch(ideaActions.getIdeaDetails(id));
        }
      })
      .catch((error) => {
        dispatch({ 
          type: ideaUpdateConstants.UPDATE_IDEA_FAILURE, 
          error
        });
      });
  }
}

function updateTitleDescription(payload) {
  return (dispatch) => {
    dispatch({ type: ideaUpdateConstants.PUT_TITLE_DESCRIPTION, payload });
  };
}

function updateCanvas(canvas) {
  const payload = {
    canvas,
  };

  return (dispatch) => {
    dispatch({ type: ideaUpdateConstants.PUT_CANVAS, payload });
  };
};

function updateCanvasValue(canvas) {
  const payload = {
    canvas,
  };

  return (dispatch) => {
    dispatch({ type: ideaUpdateConstants.PUT_CANVAS_VALUE, payload });
  };
};

function updateIdeaLinks(links) {
  return (dispatch) => dispatch({ type: ideaUpdateConstants.PUT_LINKS, links });
};

function updateSetImages(images) {
  return (dispatch) => {
    const payload = {
      images,
    };

    dispatch({ type: ideaUpdateConstants.PUT_SET_IMAGES, payload });
  };
}

function removeImage(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: ideaUpdateConstants.REMOVE_IMAGE, payload });
  };
}

function updateSetVideo(video) {
  return (dispatch) => {
    const payload = {
      video,
    };

    dispatch({ type: ideaUpdateConstants.PUT_SET_VIDEO, payload });
  };
}

function setSelectedBusiness(payload) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.SET_PUT_SELECTED_BUSINESS,
      payload
    })
  }
}

function removeVideo(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: ideaUpdateConstants.REMOVE_VIDEO, payload });
  };
} 

function updateSetFile(file) {
  return (dispatch) => {
    const payload = {
      file,
    };

    dispatch({ type: ideaUpdateConstants.PUT_SET_FILE, payload });
  };
}

function removeFile(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: ideaUpdateConstants.REMOVE_FILE, payload });
  };
} 


function sendRemoveFiles(ideaId, fileIds) {
  return (dispatch) => {
    ideaService.removeFiles(ideaId, fileIds).then(() => dispatch({type: 'SEND_REMOVE_FILES'})).catch(error => {
      dispatch({type: 'SEND_REMOVE_FILES_ERROR'});
      console.log(error);
    });
  };
};

function loadChallenges(data) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.LOAD_EDIT_IDEA_CHALLENGES,
      payload: data
    });
  }
}

function loadClassifications(data) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.LOAD_EDIT_IDEA_CLASSIFICATIONS,
      payload: data
    });
  }
}

function changeField(name, value) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.CHANGE_IDEIA_EDIT_FIELD,
      payload: { name, value }
    })
  }
}

function changeFieldClassification(data) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.CHANGE_FIELD_CLASSIFICATION,
      payload: data
    });
  }
}

function addLink() {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.ADD_IDEIA_EDIT_LINK,
    })
  }
}

function removeLink(index) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.REMOVE_IDEIA_EDIT_LINK,
      payload: index
    })
  }
}

function changeLink(value, index) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.CHANGE_IDEA_EDIT_LINK,
      payload: {
        value,
        index
      }
    })
  }
}

function clearFieldChallenge(challenge) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.CLEAR_CHALLENGE_FIELD,
      payload: challenge
    });
  }
}

function clearFieldClassification() {
  return dispatch => {
    dispatch({ type: ideaUpdateConstants.CLEAR_CLASSIFICATION_FIELD });
  }
}

function changeFieldParticipant(data, index) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.IDEA_UPDATE_CHANGE_PARTICIPANT_FIELD,
      payload: { data, index }
    });
  }
}

function clearFieldParticipant(index) {
  return (dispatch) => {
    dispatch({
      type: ideaUpdateConstants.IDEA_UPDATE_CLEAR_PARTICIPANT_FIELD,
      payload: index
    });
  }
}

function addNewParticipant() {
  return dispatch => {
    dispatch({ type: ideaUpdateConstants.IDEA_UPDATE_ADD_NEW_PARTICIPANT });
  }
}

function removeParticipant(value) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.IDEA_UPDATE_REMOVE_PARTICIPANT,
      payload: value
    });
  }
}

function loadParticipants(data) {
  return dispatch => {
    dispatch({
      type: ideaUpdateConstants.IDEA_UPDATE_LOAD_PARTICIPANTS,
      payload: data
    });
  }
}

function getParticipants() {
  return async dispatch => {
    dispatch({ type: ideaUpdateConstants.IDEA_UPDATE_GET_PARTICIPANTS_REQUEST });

    try {
      const data = await ideaService.getParticipants();

      dispatch({
        type: ideaUpdateConstants.IDEA_UPDATE_GET_PARTICIPANTS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ideaUpdateConstants.IDEA_UPDATE_GET_PARTICIPANTS_FAILURE,
        error: error.toString()
      });
    }
  }
}