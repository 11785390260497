import React from 'react';
import { Container, makeStyles, Button, Grid, Box, CircularProgress } from '@material-ui/core';
import { BackNavAdmin, ArticleCard, Search, DialogAllCreateIdea } from '../../components';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ideaCreateActions, ideaCreateDialogsActions, timelineActions } from '../../actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '../../components/Shared';
import { portfolioIcon } from '../../assets/imgs';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  btnNewArticle: {
    float: 'right',
    padding: theme.spacing(1, 2),
  },
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

function ArticlesPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { highlightIdeas, pagingIdea, loading, paging: { search, limit } } = useSelector(state => state.timeline);
  const { articleQuestions } = useSelector((state) => state.ideaCreate);
  const { dialogTitleDescription } = useSelector((state) => state.ideaCreateDialogs);
  
  useEffect(() => {
    dispatch(timelineActions.getHighlightIdeas(1, { createdAt: -1 }, 50));
  }, [dispatch]);

  useEffect(() => {
    if (config.SHOW_ADITIONAL_INNOVATION_FIELDS && dialogTitleDescription) {
      dispatch(ideaCreateActions.getArticleQuestions());
    }
  }, [dispatch, dialogTitleDescription]);

  useEffect(() => {
    if (config.SHOW_ADITIONAL_INNOVATION_FIELDS && dialogTitleDescription) {
      if (articleQuestions) {
        dispatch(ideaCreateActions.loadArticleQuestions(articleQuestions?.map((item) => ({
          _id: item._id,
          question: item.question,
          answer: item?.answer ?? '',
          isSelect: item?.isSelect,
          valuesToSelect: item?.valuesToSelect,
          attachmentRequired: item?.attachmentRequired
        }))));
      }
    }
  }, [dispatch, articleQuestions, dialogTitleDescription]);

  function loadMoreIdeas() {
    if (!loading) {
      var newPage = pagingIdea.page === 1 ? 2 : pagingIdea.page;

      dispatch(timelineActions.getHighlightIdeas(newPage, { createdAt: -1 }, 50));
    }
  }

  function searchIdea(query) {
    dispatch(timelineActions.getHighlightIdeas(1, { createdAt: -1 }, 50, query));
  }

  function handleOpenDialog(openFunction) {
    dispatch(openFunction())
  }

  return (
    <>
      <DialogAllCreateIdea />
      <BackNavAdmin title={!loading ? t('cases') : t('carregando')} pathname="/">
        <Button
          className={classes.btnNewArticle}
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() =>
            handleOpenDialog(ideaCreateDialogsActions.openDialogTitleDescription)
          }
        >
          {t('nova_inovacao')}
        </Button>
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.containerHeader}>
            <Search
              query={searchIdea}
              placeholder={t('pesquise_pelo_nome_da_ideia')}
            />
          </Grid>
          <Grid item xs={12} >
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="60vh">
                <CircularProgress />
              </Box>
            ) : (
              <>
                {highlightIdeas.length > 0 ? (
                  <InfiniteScroll
                    dataLength={highlightIdeas.length}
                    next={loadMoreIdeas}
                    hasMore={true}
                    scrollThreshold={'90%'}
                    style={{ overflow: 'hidden', paddingBottom: 20 }}
                  >
                    <Grid container spacing={2}>
                      {highlightIdeas.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                          <ArticleCard
                            idea={item}
                            link={`/idea/${item._id}/general`}
                            image={item.cardThumbnail?.url ?? ""}
                            isFeed={false}
                            index={index}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </InfiniteScroll>
                ) : (
                  <Box marginTop="2rem">
                    <EmptyState
                      icon={portfolioIcon}
                      description={t('ainda_nao_ha_inovacoes_publicados')}
                    />
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { ArticlesPage };
