import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box, CardContent, IconButton, CardActions } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { history, truncate } from '../../helpers';
import { CardMedia } from '@material-ui/core';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';
import { timelineActions } from '../../actions';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    '& .MuiTypography-root': {
      textTransform: 'none',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.primary.dark,
  },
  description: {
    color: theme.palette.neutrals.low.dark,
    fontWeight: 500,
    fontSize: 11,
  },
  media: {
    paddingTop: '46.25%',
    width: '100%',
    cursor: 'pointer'
  },
  upsFeedContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.neutrals.low.light,
    borderTop: `1px solid ${theme.palette.neutrals.high.dark}`
  },
  upsInnovationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    gap: 10,
    color: theme.palette.neutrals.low.light
  },
  addIcon: {
    color: theme.palette.primary.main,
    fontSize: 12
  },
  seeAllContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    '&:hover': {
      color: theme.palette.neutrals.high.dark,
      textDecoration: 'underline',
    },
  },
  textSeeAll: {
    fontSize: 12,
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
  },
  cardContent: {
    height: 150,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    cursor: 'pointer'
  }
}));

function ArticleCard({ idea, link, image, isFeed = false, index, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.user);
  const { loading, loadingUpIdea, pagingIdea: { query } } = useSelector((state) => state.timeline);
  const { t } = useTranslation();

  function goToIdea() {
    history.push(link);
  }

  function handleUpIdea() {
    dispatch(timelineActions.upIdea(idea._id, 1, { createdAt: -1 }, 10, query, isFeed));
  }

  return (
    <Card
      {...props}
      style={{ margin: 10 }}
      className={classes.root}
      elevation={0}
    >
      <CardMedia
        className={classes.media}
        image={image || imageCard}
        title={idea?.name}
        onClick={goToIdea}
      />
      <CardContent onClick={goToIdea} className={classes.cardContent}>
        <Box>
          <Typography className={classes.title}>
            {truncate(idea?.name, 40, '...')}
          </Typography>
          <Typography className={classes.description}>
            {truncate(idea?.description, 100, '...')}
          </Typography>
        </Box>
        <Box className={classes.seeAllContainer}>
          <Typography className={classes.textSeeAll} onClick={goToIdea}>
            {t('ver_mais')}
          </Typography>
          <Add className={classes.addIcon} />
        </Box>
      </CardContent>
      <CardActions
        className={classes.upsFeedContainer}
        style={{ justifyContent: userLogged._id !== idea?.user ? 'space-between' : 'flex-end' }}
      >
        {userLogged._id !== idea?.user && (
          <IconButton
            color={(idea?.ups !== undefined && idea?.ups?.filter(item => item === userLogged?._id))[0] ? 'primary' : 'default'}
            onClick={handleUpIdea}
            size="small"
            style={{ fontSize: 14.22 }}
            disabled={loadingUpIdea || loading}
          >
            <ThumbUpAltIcon />
          </IconButton>
        )}
        <Typography variant="subtitle2">
          {idea?.ups?.length ?? 0}{idea?.ups?.length > 1 ? ' Votos' : ' Voto'}
        </Typography>
      </CardActions>
    </Card>
  );
}

export { ArticleCard };
