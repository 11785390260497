import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';

export const articlesService = {
  getArticles,
  createArticle,
  uploadArticleImage,
  getArticleById,
  deleteArticle,
  putArticle,
  getArticleQuestions,
  upArticle,
  getHighlightArticles
};

function deleteArticle(id) {
  return axios
    .delete(`${config.URL_BASE}/articles/${id}`, requestHeaders())
    .then(handleResponse);
}

function getArticleById(id) {
  return axios
    .get(`${config.URL_BASE}/articles/${id}`, requestHeaders())
    .then(handleResponse);
}

function getArticles(page=1, sort={createdAt: -1}, limit=10, searchText="") {
  return axios
    .get(
      `${config.URL_BASE}/articles?page=${page}&sort=${JSON.stringify(
        sort
      )}&limit=${limit}&searchText=${searchText}`,
      requestHeaders()
    )
    .then(handleResponse);
}

function createArticle(article) {
  return axios
    .post(`${config.URL_BASE}/articles`, article, requestHeaders())
    .then(handleResponse);
}

function putArticle(article) {
  return axios
    .put(`${config.URL_BASE}/articles/${article._id}`, article, requestHeaders())
    .then(handleResponse);
}

function uploadArticleImage(type, file, id) {
  console.log('article', {type, file, ideaId});
  const data = new FormData();

  data.append(type, file?.file, file?.name);

  return axios.post(`${config.URL_BASE}/uploads/article/${id}`, data, requestHeaders());
};

function getArticleQuestions() {
  return axios.get(`${config.URL_BASE}/articles-questions`, requestHeaders());
}

function upArticle(articleId) {
  return axios
  .put(`${config.URL_BASE}/articles/ups/${articleId}`, {}, requestHeaders())
  .then(handleResponse)
  .then((response) => response.data);
}

function getHighlightArticles(page, sort, limit, query) {
  const hasQuery = query
    ? `ideas/all?filter={"field":"name","value":"${query}"}&page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`
    : `ideas/all?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`;

  return axios
    .get(`${config.URL_BASE}/${hasQuery}`, requestHeaders())
    .then(handleResponse);
}