import React, { useEffect, useState, useCallback } from 'react';
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import Fab from "@material-ui/core/Fab"
import {
  Idea,
  FeedIdentity,
  Contents,
  Ranking,
  Copyright,
  IdeaCreateBoxFeed,
  IdeaPoints,
  DialogAllCreateIdea,
  ChallengeBanner,
  FilterTimeline,
  HighlightCard,
  SkeletonCardsHome,
  SkeletonChallengeBanner,
  SkeletonContentsFeed,
  HighLightList,
  HighLightArticleList,
} from '../../components';
import { ModalWelcome } from '../../components/ModalWelcome/ModalWelcome';
import { useDispatch, useSelector } from 'react-redux';
import {
  timelineActions,
  userActions,
  ideaCreateActions,
  ideaCreateDialogsActions,
  contentsActions,
} from '../../actions';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLastLocation } from 'react-router-last-location';
import stylesHome from "./styles"
import config from '../../config';

function HomePage() {
  const dispatch = useDispatch();
  const classes = stylesHome()
  const [welcomePopUp, setWelcomePopUp] = useState(false);
  const [lastLocation] = useState(useLastLocation());
  const ideas = useSelector((state) => state.timeline.ideas);
  const {
    page,
    sort,
    limit,
    search,
    hasNextPage
  } = useSelector((state) => state.timeline.paging);
  const {
    loadingUpIdea,
    loadingUpComment,
    loadingChallenges,
    challenges
  } = useSelector(state => state.timeline);
  const { details, _id } = useSelector((state) => state.user);
  const loading = useSelector((state) => state.timeline.loading);
  const { contentLoading } = useSelector(state => state.contents);
  const { articleQuestions } = useSelector((state) => state.ideaCreate);
  const { dialogTitleDescription } = useSelector((state) => state.ideaCreateDialogs);

  useEffect(() => {
    dispatch(timelineActions.getHighlightIdeas(1, { createdAt: -1 }, limit));
  }, [dispatch]);

  useEffect(() => {
    if (config.SHOW_ADITIONAL_INNOVATION_FIELDS && dialogTitleDescription) {
      dispatch(ideaCreateActions.getArticleQuestions());
    }
  }, [dispatch, dialogTitleDescription]);

  useEffect(() => {
    if (config.SHOW_ADITIONAL_INNOVATION_FIELDS && dialogTitleDescription) {
      if (articleQuestions) {
        dispatch(ideaCreateActions.loadArticleQuestions(articleQuestions?.map((item) => ({
          _id: item._id,
          question: item.question,
          answer: item?.answer ?? '',
          isSelect: item?.isSelect,
          valuesToSelect: item?.valuesToSelect,
          attachmentRequired: item?.attachmentRequired
        }))));
      }
    }
  }, [dispatch, articleQuestions, dialogTitleDescription]);

  const handleLoadHome = useCallback(() => {
    dispatch(userActions.getUserDetailsRaking());
    dispatch(timelineActions.getBannersChallenge());
    dispatch(contentsActions.getContents());
    dispatch(timelineActions.getTimeline(1, { timelineDate: -1 }, search ));
    dispatch(timelineActions.getHighlightIdeas(1, { createdAt: -1 }, limit));
  }, [dispatch, search]);

  useEffect(() => {
    if (lastLocation?.pathname === '/register') {
      setWelcomePopUp(true)
    }
    handleLoadHome()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLocation, handleLoadHome])

  useEffect(() => {
    return () => dispatch(timelineActions.clearAll());
  }, [dispatch]);

  function loadMoreIdeas() {
    if (!loading && hasNextPage) {
      dispatch(timelineActions.getTimeline(page, sort, search));
    }
  }

  function handleOpenDialogTitleDescription() {
    dispatch(ideaCreateDialogsActions.openDialogTitleDescription())
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <ModalWelcome openWelcomePopUp={welcomePopUp} />
      <DialogAllCreateIdea />
      <Grid container spacing={3}>
        <Hidden xsDown>
          <Grid item xs={4} md={3}>
            <FeedIdentity />
            <Box className={classes.fixed}>
              <IdeaPoints ideaTotal={details?.idea} walletAmmount={details?.wallet[0]?.amount} userId={_id} />
              {config.SHOW_RANKING_HOME_CARD && <Ranking ranking={details?.ranking} />}
              <Copyright />
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={8} md={config.SHOW_TIMELINE_CHALLENGES || config.SHOW_TIMELINE_CONTENTS ? 6 : 9}>
          <Hidden smUp>
            <Fab aria-label="add" className={classes.fab} color="secondary" onClick={handleOpenDialogTitleDescription}>
              <EmojiObjectsIcon style={{ fontSize: 36 }} />
            </Fab>
          </Hidden>
          <Hidden smDown>
            <IdeaCreateBoxFeed />
          </Hidden>
          <Hidden smUp>
            {challenges?.length > 0 && <ChallengeBanner />}
          </Hidden>
          <HighLightArticleList />
          <FilterTimeline />
          <HighlightCard />
          <InfiniteScroll
            dataLength={ideas.length}
            next={loadMoreIdeas}
            hasMore={true}
            scrollThreshold={'90%'}
            loader={
              loading && (
                <SkeletonCardsHome />
              )
            }
          >
            {ideas.length > 0 && ideas.map((idea, index) => (
              <Idea
                index={index}
                idea={idea}
                key={idea._id}
                loadingUpIdea={loadingUpIdea}
                loadingUpComment={loadingUpComment}
              />
            ))}
          </InfiniteScroll>
        </Grid>
        {(config.SHOW_TIMELINE_CHALLENGES || config.SHOW_TIMELINE_CONTENTS) && <Hidden smDown>
          <Grid item xs={3}>
            {config.SHOW_TIMELINE_CHALLENGES && (loadingChallenges ? <SkeletonChallengeBanner /> : <ChallengeBanner />)}
            {config.SHOW_TIMELINE_CONTENTS && (contentLoading ? <SkeletonContentsFeed /> : <Contents />)}
          </Grid>
        </Hidden>}
      </Grid>
    </Container>
  );
}

export { HomePage };
